$(document).ready(function() {

	var owl = $("#client-slider");
	owl.owlCarousel({
		pagination: false,
		scrollPerPage: true,
		slideSpeed: 600,
		items : 8,
    itemsDesktop : [1000,8],
    itemsDesktopSmall : [750,6],
    itemsTablet: [580,4],
    itemsMobile : [400,3]
	});
	$("#carousel-nav .next").click(function(){
    owl.trigger('owl.next');
  });
	$("#carousel-nav .prev").click(function(){
		owl.trigger('owl.prev');
	})

	// tabs
	if ($('.tab-switch').length) {
		$('.hidden-tab').hide();
		$('.tab-switch li a').click(function(e) {
			e.preventDefault();
			if (!$(this).hasClass('active')) {
				$('.tab-switch li a').removeClass('active');
				$(this).addClass('active');
				$('.tab').hide();
				$('#' + $(this).attr('data-slide')).show();
			}
		})
	}

	// $("#product-slider").slides({
	// 	generatePagination: false,
	// 	generateNextPrev: true,
	// 	play: 5000,
	// 	hoverPause: true,
	// 	pause: 5000
	// });

	 $("#tweet-box, #tweet-box-side").tweet({
		username: "OXLEYNETS",
		join_text: "auto",
		count: 2,
		template: "{text}",
		loading_text: "loading tweets..."
	});

	$("#product-enquiry-form").ajaxForm({
        success: function(response) {
            if(response.success) {
            	document.location = "/thanks";
            } else {
            	$("#product-enquiry-message").html("Error. Please check the form and try again.").attr("class","message-error").show();
            }
        }
    });

    $("#contact-form").ajaxForm({
        success: function(response) {
            if(response.success) {
            	document.location = "/thanks";
            } else {
            	$("#contact-message").html("Error. Please check the form and try again.").attr("class","message-error").show();
            }
        }
    });

});

$(function() {
	$(".slide span:nth-child(5n)").addClass('row-end');
});
